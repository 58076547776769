<template>
    <ODialog @shown="focusArea()" v-model:show="showDialog" disableResize :title="$t('Request Access')"
        :disableMovable="false" disableFade>
        <div class="o365-dialog-body">
            <div class="col-12">
                <label>{{ $t("Reason") }}</label>
                <OTextArea ref="textAreaRef" class="form-control form-control-sm" @keydown="event => keyDownHendler(event)"
                    v-model="reason"></OTextArea>
            </div>
            <div class="col-12">
                <div class="border my-2"></div>
                <div class="d-flex">
                    <div v-if="previousReason">
                        {{ $t('Previous reason: ') }} <a href="#" @click="usePreviousReason">{{ previousReason }}</a>
                    </div>
                    <div class="ms-auto">
                        <button class="btn btn-sm btn-primary" @click="activateAccess()" :disabled="reasonNotNull">{{
                            $t("Activate") }}</button>
                    </div>
                </div>
            </div>
        </div>
    </ODialog>
</template>

<script setup lang="ts">
import { ref, inject, computed, type Ref } from 'vue';
import { $t } from 'o365-utils';
import { API } from 'o365-modules';
import { alert } from 'o365-vue-services';
import { ODialog, OTextArea } from 'o365-ui-components';

const showDialog = ref(true);
const reason: Ref<string | null> = ref(null);

const setOnOpen = inject("set-on-open", () => { })
const data = inject("data");
const textAreaRef = ref(null);

const previousReason = ref(window.localStorage.getItem('previousReason' + data.type));

const reasonNotNull = computed(() => {
    return reason.value == null || reason.value == '';
})

async function activateAccess() {
    showDialog.value = false;
    const resp = await API.requestPost("/api/user/activate/" + data.type, {
        personUserId: data.personUserId,
        reason: reason.value
    });
    if (resp == true) {
        window.localStorage.setItem('previousReason' + data.type, reason.value);

        const returnUrl = getReturnUrl();
        if (!returnUrl) {
            location.reload();
        }
        else {
            location.href = returnUrl;
        }
    } else {
        alert($t("Failed to get developer privileges"), "danger", { autohide: true });
    }
}

function getReturnUrl() {
    const returnUrl = new URLSearchParams(window.location.search).get("ReturnUrl");
    if (!returnUrl || isAbsoluteUrl(returnUrl)) {
        return null;
    }
    return returnUrl;
}
function keyDownHendler(event) {
    console.log(4)
    if (!event.ctrlKey && ["enter"].includes(event.key.toLowerCase())) {
        event.preventDefault()
        if (!reasonNotNull.value) {
            activateAccess()
        }
        return
    } else if (event.ctrlKey && ["enter"].includes(event.key.toLowerCase())) {
        event.preventDefault()
        reason.value += "\n"
        return
    }
}
function isAbsoluteUrl(url) {
    try {
        new URL(url);
        return true;
    } catch {
    }
    return false;
}

function usePreviousReason() {
    reason.value = previousReason.value;
    textAreaRef.value?.activateEditor()
}
function focusArea() {

    textAreaRef.value?.activateEditor()


}
setOnOpen(() => {
    showDialog.value = true
})
</script>